.root {
  /* fallback option */
  word-break: break-all;
  /* use break-word if available */
  word-break: break-word;
  hyphens: auto;
}

.media {
  width: 100%;
  border-radius: 8px;
  margin-bottom: 0;
}

.text {
  width: 100%;

  &:first-child {
    margin-top: 0;
  }

  /* we style global elements only because they are generated by markdown processor */
  & a {
    color: var(--colorBrown72);

    &:hover {
      color: var(--marketplaceColor);
      text-decoration: none;
    }
  }

  & ol {
    margin-top: 0px;
    padding-left: 0px;
  }

  & li {
    margin-top: 12px;
    border-top: 1px solid var(--colorBrown8);
    padding-top: 12px;

    font-size: 15px;
    line-height: 1.3;
    color: var(--colorGrey700);
    list-style-position: inside;

    &:first-of-type {
      margin-top: 0;
      border-top: none;
    }
  }

  & p {
    margin-bottom: 15px;
    padding: 0px;

    font-family: var(--fontFamilyHeading);
    font-size: 20px;
    line-height: 24px;

    color: var(--colorBrown100);
  }
}
