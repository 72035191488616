@import '../../../styles/customMediaQueries.css';

/* Styles for custom sections: classes shared by <SectionColumns>, <SectionArticle>, <SectionFeatures>, <SectionCarousel>, <SectionHero> etc. */
.sectionDetails {
  max-width: var(--contentMaxWidthPages);
  display: grid;
  margin: 0 auto;
  padding: 0 24px;
  position: relative;

  @media (--viewportMedium) {
    justify-content: start;
  }

  & h1 + p,
  & h1 + a,
  & h1 + ul,
  & h1 + ol,
  & h1 + code,
  & h1 + div,
  & h1 + h1,
  & h1 + h2,
  & h1 + h3,
  & h1 + h4,
  & h1 + h5,
  & h1 + h6 {
    margin-top: 12px;
  }

  & h2 + p,
  & h2 + a,
  & h2 + ul,
  & h2 + ol,
  & h2 + code,
  & h2 + div,
  & h2 + h1,
  & h2 + h2,
  & h2 + h3,
  & h2 + h4,
  & h2 + h5,
  & h2 + h6 {
    margin-top: 12px;
  }

  & h3 + p,
  & h3 + a,
  & h3 + ul,
  & h3 + ol,
  & h3 + code,
  & h3 + div,
  & h3 + h1,
  & h3 + h2,
  & h3 + h3,
  & h3 + h4,
  & h3 + h5,
  & h3 + h6 {
    margin-top: 12px;
  }

  & h4 + p,
  & h4 + a,
  & h4 + ul,
  & h4 + ol,
  & h4 + code,
  & h4 + div,
  & h4 + h1,
  & h4 + h2,
  & h4 + h3,
  & h4 + h4,
  & h4 + h5,
  & h4 + h6 {
    margin-top: 12px;
  }

  & h5 + p,
  & h5 + a,
  & h5 + ul,
  & h5 + ol,
  & h5 + code,
  & h5 + div,
  & h5 + h1,
  & h5 + h2,
  & h5 + h3,
  & h5 + h4,
  & h5 + h5,
  & h5 + h6 {
    margin-top: 12px;
  }

  & h6 + p,
  & h6 + a,
  & h6 + ul,
  & h6 + ol,
  & h6 + code,
  & h6 + div,
  & h6 + h1,
  & h6 + h2,
  & h6 + h3,
  & h6 + h4,
  & h6 + h5,
  & h6 + h6 {
    margin-top: 12px;
  }

  @media (--viewportMedium) {
    justify-content: center;
  }
}

.headerReverse p {
  order: -1;

  font-family: var(--fontFamilyHeading);
  line-height: 12px;
  margin-top: 0 !important;
  margin-bottom: 16px;
  letter-spacing: 0.3em;
  text-transform: uppercase;
}

.blockContainer {
  padding: 64px 32px 0 32px;

  @media (--viewportMedium) {
    padding: 64px 32px 0 32px;
  }

  &:first-child {
    padding: 0 32px;
  }
}

.align {
  text-align: left;
  justify-self: start;

  @media (--viewportMedium) {
    text-align: center;
    justify-self: center;
  }
}

.alignCta {
  text-align: left;

  @media (--viewportMedium) {
    text-align: center;
    justify-self: center;
  }
}

.title {
  composes: align;
  max-width: 40ch;
}

.description {
  composes: align;
  max-width: 65ch;
}

.ctaButton {
  composes: alignCta;

  display: inline-block;

  margin-top: 32px;
  padding: 14px 32px;
  width: 100%;

  font-size: 15px;
  background-color: var(--marketplaceColor);
  border-radius: var(--borderRadiusRound);
  color: white;
  text-decoration: none;
  text-align: center;
  font-weight: 500;

  &:hover {
    text-decoration: none;
    background-color: var(--marketplaceColorDark);
  }

  @media (--viewportMedium) {
    width: auto;
  }
}

.ctaButtonLight {
  border: none !important;

  background-color: var(--colorBrownLight);
  color: var(--marketplaceColorBrown) !important;

  &:hover {
    background-color: var(--marketplaceColor);
    color: white !important;
  }
}

.ctaButtonSecondary {
  composes: ctaButton;

  padding: 13px 32px;
  border: 1px solid var(--marketplaceColor);

  background-color: var(--colorBrownLight);
  color: var(--marketplaceColor);

  &:hover {
    background-color: var(--marketplaceColor);
    color: white;
  }
}

.ctaButtonLink {
  composes: align;
  display: inline-block;
  font-size: 15px;
  color: var(--marketplaceColor);
  font-weight: 500;
  margin-top: 16px;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

/**
 * Theme: dark 
 * These styles are at the bottom of the file so that they overwrite rules for default "light" theme.
 */
.darkTheme h1,
.darkTheme h2,
.darkTheme h3,
.darkTheme h4,
.darkTheme h5,
.darkTheme h6 {
  color: var(--colorWhite);

  &::selection {
    background-color: cyan;
    color: unset;
  }
}

.darkTheme p,
.darkTheme li,
.darkTheme blockquote {
  color: rgba(255, 255, 255, 1);

  &::selection {
    background-color: cyan;
    color: unset;
  }
}

/* link on dark theme */
.darkTheme a {
  color: white;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
    color: var(--marketplaceColorLight);
  }
}

/* button on dark theme */
.darkTheme .ctaButton {
  border: 1px solid var(--marketplaceColorDark);
  text-decoration: none;

  &:hover {
    color: white;
  }
}

.darkTheme hr {
  border-color: var(--colorGrey700);
}

/* dark inline code */
.darkTheme code {
  background-color: var(--colorGrey700);
  color: var(--colorGrey100);
}

/* dark code block */
.darkTheme pre {
  background-color: var(--colorGrey700);
}
