@import '../../../../styles/customMediaQueries.css';

.root {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  width: 100%;
  position: relative;

  &:nth-of-type(odd) {
    background-color: var(--colorWhite);
  }

  &:target {
    scroll-margin-top: var(--topbarHeight);
    @media (--viewportMedium) {
      scroll-margin-top: var(--topbarHeightDesktop);
    }
  }
}

.containerBoxed {
  min-height: unset !important;
  padding: 44px 0;

  @media (--viewportMedium) {
    padding: 96px 0;
  }
}

.containerNoPaddingTop {
  padding-top: 0 !important;
}

.sectionContent {
  padding: 64px 0;
  position: relative;
  word-break: break-word;

  @media (--viewportMedium) {
    padding: 64px 0;
  }
}

.backgroundImageWrapper {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.contentBoxed {
  display: flex;
  justify-content: center;

  padding: 48px 0;

  @media (--viewportMedium) {
    padding: 96px 0;
  }

  & img {
    border-radius: var(--borderRadiusXLarge);
    max-width: 1120px;
  }
}

.contentNoPaddingTop {
  padding-top: 0 !important;
}

.contentSlim {
  padding: 16px 0;
}

.boxedParagraph {
  color: var(--colorBrown100);
}
